import React from 'react';
import './index.scss';

import Portrait from '../../../../assets/images/portrait.jpeg';
import Jamaya from '../../../../assets/images/jamaya.JPG';
import Rumrum from '../../../../assets/images/rumrum.png';
import ScrollImg from '../../../../assets/images/scroll.png';

function changeTab(){
    const tabs = document.querySelectorAll('.tab-btn');
    const all_row = document.querySelectorAll('.row');

    tabs.forEach((tab, index)=> {
        tab.addEventListener('click', (e) => {
            e.stopPropagation();
            console.log('alert');
            tabs.forEach(tab => {tab.classList.remove('active');});
            tab.classList.add('active');

            all_row.forEach(row => {row.classList.remove('active');});
            all_row[index].classList.add('active');
        })
    });
}

const Jobs = () => {
    return (
      <div id="jobs" className="jobs-container">
        <div className="tab-nav-bar">
          <div className="tab-navigation">
            <ul className="tab-menu">
              <li id="1" className="tab-btn active" onClick={changeTab}>
                Jamaya
              </li>
              <li id="2" className="tab-btn" onClick={changeTab}>
                Rum Rum
              </li>
              <li id="2" className="tab-btn" onClick={changeTab}>
                Portrait
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab">
            <div className="row active">
              <div className="left">
                <div className="info">
                  <h2 className="title">Jamaya</h2>
                  <p className="summary">
                  After Great Food, Drinks & Good Music?
                  <br />
                  <br />
                  Jamaya Provides A Unique Dining Experience With Great Music Provided By Myself And Other Resident DJs
                  </p>
                </div>
              </div>
              <div className="right">
                <img loading="lazy" src={Jamaya} alt="" />
              </div>
            </div>
            <div className="row">
              <div className="left">
                <div className="info">
                  <h2 className="title">Rum Rum</h2>
                  <p className="summary">
                  Shutdown Saturdays At Rum Rum, The Best Saturday Night In Birmingham. Playing The Best In R&B, Hip-Hop, Dancehall, Afrobeats And Many More
                  </p>
                </div>
              </div>
              <div className="right">
                <img loading="lazy" src={Rumrum} alt="" />
              </div>
            </div>
            <div className="row">
              <div className="left">
                <div className="info">
                  <h2 className="title">Portrait</h2>
                  <p className="summary">
                    Out for the night in Birmingham? Catch me playing at
                    Portrait.
                  </p>
                </div>
              </div>
              <div className="right">
                <img loading="lazy" src={Portrait} alt="" />
              </div>
            </div>
          </div>
        </div>
        <button className="img-scroll">
          <p>Scroll Down</p>
          <img src={ScrollImg} alt="" />
        </button>
      </div>
    );
}

export default Jobs;